import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { FotoService } from 'src/app/_services/foto.service';
import { Foto } from 'src/app/_interfaces/foto';

@Component({
  selector: 'app-fotos',
  templateUrl: './fotos.component.html'
})
export class FotosComponent implements OnInit {

  ano: any;
  mes: any;
  fotos: Foto[];

  constructor(private activatedRoute: ActivatedRoute, private fotoService: FotoService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.ano = params.ano;
      this.mes = params.mes;

      this.listaFotos();
    });
  }

  listaFotos() {
    this.fotoService.listaFotos(this.ano, this.mes)
      .subscribe(
        result => { 
          this.fotos = result;
        },
        erro => { console.log('Erro: ' + erro) },
        () => { }
      );
  }

}