import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { AcomodacoesComponent } from './pages/acomodacoes/acomodacoes.component';
import { CaminhoComponent } from './pages/caminho/caminho.component';
import { FotosComponent } from './pages/fotos/fotos.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { ErrorComponent } from './pages/error/error.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'sobre', component: SobreComponent },
  { path: 'acomodacoes', component: AcomodacoesComponent },
  { path: 'caminho', component: CaminhoComponent },
  { path: 'fotos/:ano/:mes', component: FotosComponent },
  { path: 'contato', component: ContatoComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }