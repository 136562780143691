import { Component, OnInit } from '@angular/core';

import { FotoService } from 'src/app/_services/foto.service';
import { FotoAno } from 'src/app/_interfaces/fotoAno';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html'
})
export class HeaderMenuComponent implements OnInit {

  fotoAnos: FotoAno[] = [];
  dataAtual: number = Date.now();

  constructor(private fotoService: FotoService) { }

  ngOnInit() {
    this.fotoService.listaFotoAnos()
        .subscribe(f => this.fotoAnos = f);
  }
}