import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Recado } from '../_interfaces/recado';

@Injectable({
  providedIn: 'root'
})
export class RecadoService {

  private endPoint = environment.apiUrl + 'recado';

  constructor(private http: HttpClient) { }

  enviar(recado: Recado) {
    return this.http.post<object>(this.endPoint, recado);
  }
}