import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { AppRoutingModule } from '../app-routing.module';
import { LayoutModule } from '../layout/layout.module';

import { HomeComponent } from './home/home.component';
import { CaminhoComponent } from './caminho/caminho.component';
import { ErrorComponent } from './error/error.component';
import { ContatoComponent } from './contato/contato.component';
import { FotosComponent } from './fotos/fotos.component';
import { AcomodacoesComponent } from './acomodacoes/acomodacoes.component';
import { SobreComponent } from './sobre/sobre.component';

@NgModule({
  declarations: [
    HomeComponent,
    CaminhoComponent,
    ErrorComponent,
    ContatoComponent,
    FotosComponent,
    AcomodacoesComponent,
    SobreComponent
  ],
  exports:[

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LayoutModule
  ]
})
export class PagesModule { }