import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '../app-routing.module';

import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PageBannerComponent } from './page-banner/page-banner.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    PageBannerComponent,
    HeaderMenuComponent
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    PageBannerComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule
  ]
})
export class LayoutModule { }