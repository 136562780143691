import { Component, OnInit } from '@angular/core';

import { Recado } from 'src/app/_interfaces/recado';
import { RecadoService } from 'src/app/_services/recado.service';
import { FormGroup, FormBuilder, Validators, EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html'
})
export class ContatoComponent implements OnInit {

  form: FormGroup;
  submitted: boolean = false;
  recado: Recado = new Recado;
  statusRecado: string;

  constructor(private recadoService: RecadoService, private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      nome: [null, [Validators.required, Validators.maxLength(100)]],
      email: [null, [Validators.required, Validators.email]],
      mensagem: [null, [Validators.required, Validators.maxLength(500), Validators.minLength(5)]]
    });

  }

  hasError(field: string){
    return this.form.get(field).errors;
  }

  onSubmit() {
    this.submitted = true;

    if(this.form.valid){
      this.recado = this.form.value;
      this.recadoService.enviar(this.recado)
      .subscribe(
        result => { alert(result['message']); },
        erro => { console.log('Erro: ' + erro) },
        () => { }
      );
      
    }
  }

}