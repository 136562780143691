import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html'
})
export class PageBannerComponent implements OnInit {

   @Input() nomePagina = '';

  constructor() { }

  ngOnInit() {
  }

}