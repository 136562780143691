import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Foto } from '../_interfaces/foto';
import { FotoAno } from '../_interfaces/fotoAno';

@Injectable({
  providedIn: 'root'
})
export class FotoService {

  constructor(private http: HttpClient) { }

  listaFotoAnos() {
    return this.http.get<FotoAno[]>(environment.apiUrl + 'fotoAno/list');
  }

  listaFotos(ano: number, mes: number) {
    return this.http.get<Foto[]>(environment.apiUrl + 'foto/list?ano='+ ano +'&mes='+ mes);
  }
}